import React from 'react'
import ReactDOM from 'react-dom/client'
import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import Helmet from 'react-helmet'
import { I18nextProvider, initReactI18next, useTranslation } from 'react-i18next'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ScrollToTop } from './components'
import {
  ConsumerPrivacyPage,
  DsarPage,
  Error404Page,
  HomePage,
  JoinPage,
  MobileAppDoNotSellPage,
  MobileAppHome,
  MobileAppHowItWorksPage,
  MobileAppPrivacyPolicyPage,
  MobileAppLayout,
  PartnersPage,
  PressPage,
  PurposePage,
  RetailerPage,
  SuccessPage,
  ShopPage,
  WaitingListPage,
} from './pages'

import favicon from './images/favicon/favicon.ico'

import Layout from './Layout'

import strapiInstance from './api'

const backendOptions = {
  loadPath: `${strapiInstance.defaults.baseURL}/translation?locale={{lng}}`,
  request: (options, url, payload, callback) => {
    try {
      strapiInstance.get(url).then(({ data }) => {
        callback(null, {
          data: data.data.attributes.translations,
          status: 200,
        })
      })
    } catch (e) {
      //TODO: load from json
      console.error(e)
      callback(null, {
        status: 500,
      })
    }
  },
}

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: backendOptions,
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    keySeparator: false,
    languages: ['en', 'fr', 'ja'],
    react: {
      useSuspense: true,
    },
  })

const Root = () => {
  const { i18n } = useTranslation()

  const router = createBrowserRouter([
    {
      children: [
        {
          element: <MobileAppHome />,
          index: true,
        },
        {
          element: <MobileAppHowItWorksPage />,
          path: 'how-it-works',
        },
        {
          element: <MobileAppPrivacyPolicyPage />,
          path: 'privacy-policy',
        },
        {
          element: <MobileAppDoNotSellPage />,
          path: 'do-not-sell',
        },
      ],
      element: <MobileAppLayout />,
      loader: async ({ params }) => {
        const validCountries = ['en-ca', 'en-gb', 'en-us', 'fr-fr', 'ja-jp']
        const country = params?.country || validCountries[2]

        const validFilterParam = validCountries.includes(country.toLowerCase()) ? country.toLowerCase() : validCountries[2]

        return await strapiInstance(`/mobile-app-pages?populate=deep&filters[$and][0][Country][$eq]=${validFilterParam}`)
      },
      path: 'app/:country?',
      shouldRevalidate: () => false,
    },
    {
      children: [
        {
          element: <HomePage />,
          index: true,
          loader: async () => await strapiInstance('/home-page?populate[HomePageCarousel][populate]=*'),
          shouldRevalidate: () => false,
        },
        {
          element: <ShopPage />,
          loader: async () => {
            const getDeploymentCards = await strapiInstance(`/deployment-cards?populate=*&pagination[pageSize]=1000&locale=${i18n.language}`)
            const getProductCatalogues = await strapiInstance(`/product-catalogues?populate=*&pagination[pageSize]=1000&locale=${i18n.language}`)

            const strapiResponses = await Promise.all([getDeploymentCards, getProductCatalogues])
              .then(responses => {
                const deploymentCards = responses[0].data.data || []
                const productCatalogues = responses[1].data.data || []

                return ({
                  deploymentCards,
                  productCatalogues,
                })
              })
              .catch(error => {
                console.warn(error)
                return ({})
              })

            return strapiResponses
          },
          path: 'shop',
          shouldRevalidate: () => false,
        },
        {
          element: <PurposePage />,
          path: 'purpose',
        },
        {
          element: <PartnersPage />,
          loader: async () => await strapiInstance(`/partner-page?populate[PartnerBlocks][populate]=*&locale=${i18n.language}`),
          path: 'partners',
          shouldRevalidate: () => false,
        },
        {
          element: <JoinPage />,
          path: 'join',
        },
        {
          element: <PressPage />,
          loader: async () => await strapiInstance(`/articles?populate=*&pagination[pageSize]=1000&sort=id:DESC&locale=${i18n.language}`),
          path: 'press',
          shouldRevalidate: () => false,
        },
        {
          element: <WaitingListPage />,
          loader: async () => {
            const getSignupForm = await strapiInstance(`/signup-form?populate[CheckboxArea][populate]=*&locale=${i18n.language}`)
            const getWaitingContactList = await strapiInstance(`/waiting-list-contacts?populate=*&pagination[pageSize]=1000&locale=${i18n.language}`)

            const strapiResponses = await Promise.all([getSignupForm, getWaitingContactList])
              .then(responses => {
                const signupForm = responses[0].data.data || null
                const contactList = responses[1].data.data || []

                return ({
                  contactList,
                  signupForm,
                })
              })
              .catch(error => {
                console.warn(error)
                return ({})
              })

            return strapiResponses
          },
          path: 'waiting-list',
          shouldRevalidate: () => false,
        },
        {
          element: <SuccessPage />,
          loader: async () => await strapiInstance(`/succes-page?populate=*&locale=${i18n.language}`),
          path: 'success',
          shouldRevalidate: () => false,
        },
        {
          element: <DsarPage />,
          path: 'dsar',
        },
        {
          element: <ConsumerPrivacyPage />,
          path: 'consumer-privacy',
        },
        {
          element: <RetailerPage />,
          path: ':brandUrl',
        },
        {
          element: <Error404Page />,
          path: '*',
        },
      ],
      element: <Layout />,
      path: ':urlLang?',
      shouldRevalidate: () => false,
    },
  ])

  return (
    <I18nextProvider i18n={i18n}>
      <Helmet>
        <meta content="width=device-width, initial-scale=1, shrink-to-fit=no" name="viewport" />

        <link href={favicon} rel="icon" />
      </Helmet>

      <ScrollToTop />

      <RouterProvider router={router} />
    </I18nextProvider>
  )
}

ReactDOM.createRoot(document.getElementById('root')).render(<Root />)
